/* add css module styles here (optional) */

._1VphB .row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

._1VphB .form-group {
  margin-bottom: 0rem;
  /* padding: 0 15px; */
}

._1VphB .wafo-wrapper {
  margin-bottom: 1rem;
  padding: 0 15px;
}

._1VphB label {
  display: block;
  margin-bottom: 0.5rem;
}

._1VphB .form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

._1VphB .errors {
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding-left: 2rem;
  font-size: 0.85em;
  font-weight: 700;
  color: #eb5757;
  list-style-type: square;
}

._1rqSr {
  width: 1rem;
  height: 1rem;
}

._1VphB .wafo-textarea .form-control {
  height: auto;
}

/** *********************************** */
/** Multiselect */
/** *********************************** */

._2WBSN {
  position: relative;
}

._Nq59I {
  display: flex;
}

._Nq59I > .form-control {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff !important;
}

._Nq59I > button {
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._Nq59I.down > input {
  border-bottom-left-radius: 0;
}

._Nq59I.down > button {
  border-bottom-right-radius: 0;
}

._1NkOs {
  background-color: #efefef;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 100;
  position: absolute;
  width: calc(100% - 32px);
}

._1NkOs > ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  max-height: 10rem;
  overflow-y: auto;
}

._1NkOs > ul > li {
  background-color: #fff;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #efefef;
  cursor: pointer;
}

._2nS-4 {
  padding: 0.5rem;
}

._2nS-4 > div {
  background-color: #fff;
  padding: 0.25rem 1rem;
  color: #4f4f4f;
}

/** *********************************** */
/** Autocomplete */
/** *********************************** */

._17c77 {
  position: relative;
}

._18eYS {
  background-color: #fff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 100;
  position: absolute;
  width: calc(100% - 32px);
}

._18eYS > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 10rem;
  overflow-y: auto;
}

._18eYS > ul > li {
  cursor: pointer;
}

._18eYS > ul > li:hover,
._18eYS > ul > li:focus,
._18eYS > ul > li._3sodH {
  background-color: #eee;
}

._Gv146 {
  background-color: #efefef;
  padding: 0.25rem 0.5rem;
}
